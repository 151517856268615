html,
body {
  overflow: unset !important;
  min-width: none !important;
  min-width: 200px !important;
}
body {
  font-family: IBMSansThai;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0px !important;
  height: 100%;
  background-color: #f6f6f6;
  min-width: 200px !important;
}
