html,
body {
  overflow: unset !important;
  min-width: none !important;
  min-width: 420px !important;
}
body {
  font-family: IBMSansThai;
  src: local("MyFont"),
    url(./font/IBMPlexSansThaiLooped-Light.woff) format("woff");
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0px !important;
  height: 100%;
  background-color: #f6f6f6;
  min-width: 420px !important;
}
html {
  height: 100%;
  margin: 0;
}
#root {
  height: 100%;
}
.icon {
  font-family: FontAwesome-light;
  src: local("MyFont"), url(./font/fa-light-300.woff) format("woff");
  font-size: 16px;
  padding-top: 2px;
  line-height: 16px;
}
.icon-brand {
  font-family: FontAwesome-brand;
  src: local("MyFont"), url(./font/fa-brands-400.woff) format("woff");
  font-size: 20px;
  height: 24px;
}
.icon-solid {
  font-family: FontAwesome-solid;
  src: local("MyFont"), url(./font/fa-solid-900.woff) format("woff");
  font-size: 20px;
  height: 24px;
}
.icon-primary {
  font-family: FontAwesome-brands;
  src: local("MyFont"), url(./font/fa-brands-400.woff) format("woff");
  font-size: 20px;
  height: 24px;
  color: #50a759;
  margin: 5px;
}
.bg-zoom {
  background-color: #2d8cff;
}
.bg-zoom:hover {
  background-color: #2d8cff50;
}
.icon-big {
  font-family: FontAwesome-brands;
  src: local("MyFont"), url(./font/fa-brands-400.woff) format("woff");
  font-size: 26px;
  color: #888888;
  margin: 5px;
}
@font-face {
  font-family: FontAwesome-solid;
  src: local("MyFont"), url(./font/fa-solid-900.woff) format("woff");
  font-weight: 900;
}
@font-face {
  font-family: FontAwesome-light;
  src: local("MyFont"), url(./font/fa-light-300.woff) format("woff");
  font-weight: 300;
}
@font-face {
  font-family: FontAwesome-brands;
  src: local("MyFont"), url(./font/fa-brands-400.woff) format("woff");
  font-weight: 300;
}
@font-face {
  font-family: IBMSansThai;
  src: local("MyFont"),
    url(./font/IBMPlexSansThaiLooped-Light.woff) format("woff");
  font-weight: 200;
}
@font-face {
  font-family: IBMSansThai;
  src: local("MyFont"),
    url(./font/IBMPlexSansThaiLooped-Light.woff) format("woff");
  font-weight: 300;
}
@font-face {
  font-family: IBMSansThai;
  src: local("MyFont"),
    url(./font/IBMPlexSansThaiLooped-Regular.woff) format("woff");
  font-weight: normal;
}
@font-face {
  font-family: IBMSansThai;
  src: local("MyFont"),
    url(./font/IBMPlexSansThaiLooped-Bold.woff) format("woff");
  font-weight: bold;
}
.logo-login {
  width: 226px;
}
.logo-sidemenu {
  width: 129px;
}
.card-login {
  border-radius: 10px;
  border: 0px;
}
.card-login-top {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: 0px;
}
.side-menu-tab {
  height: 50px;
  justify-content: start;
  align-items: center;
  display: flex;
  border-radius: 0;
  border-top: 1px #e6e6e6 solid;
}
a:link {
  text-decoration: none;
}
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
a:active {
  text-decoration: none;
}
.btn-toggle-right {
  -webkit-animation: opbtn 0.4s normal forwards ease-in-out; /* Safari */
  animation: opbtn 0.4s normal forwards ease-in-out;
  border: 0;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 20px;
  left: 270px;
  background-color: #0000;
  z-index: 1000;
  color: #888888;
}
.btn-toggle-left {
  border: 0;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 20px;
  background-color: #0000;
  z-index: 1000;
}
.side-menu {
  width: 270px;
  -webkit-animation: op 0.2s normal forwards ease-in-out; /* Safari */
  animation: op 0.2s normal forwards ease-in-out;
  position: fixed;
  z-index: 1000;
}
.side-menu-hidden {
  -webkit-animation: cs 0.4s normal forwards ease-in-out; /* Safari */
  animation: cs 0.4s normal forwards ease-in-out;
  position: absolute;
  z-index: 1001;
}

.side-logo-height {
  height: 90px;
}
.m-t-30 {
  margin-top: 20px;
}

/* Safari */
@-webkit-keyframes cs {
  0% {
    -webkit-margin-left: 0px;
    -webkit-width: 270px;
    -webkit-display: block;
  }
  100% {
    -webkit-margin-left: -270px;
    -webkit-width: 270px;
    -webkit-display: none;
  }
}

@keyframes cs {
  0% {
    margin-left: 0px;
    width: 270px;
    display: block;
  }
  100% {
    margin-left: -270px;
    width: 270px;
    display: none;
  }
}
/* Safari */
@-webkit-keyframes cso {
  0% {
    -webkit-margin-left: -270px;
    -webkit-width: 270px;
    -webkit-display: none;
  }
  100% {
    -webkit-margin-left: 0px;
    -webkit-width: 270px;
    -webkit-display: block;
  }
}

@keyframes cso {
  0% {
    margin-left: -270px;
    width: 270px;
    display: none;
  }
  100% {
    margin-left: 0px;
    width: 270px;
    display: block;
  }
}

/* Safari */
@-webkit-keyframes csob {
  0% {
    -webkit-margin-left: 0px;
    -webkit-width: 270px;
    display: block;
  }
  100% {
    -webkit-margin-left: 0px;
    -webkit-width: 270px;
    -webkit-display: block;
  }
}

@keyframes csob {
  0% {
    margin-left: 0px;
    width: 270px;
    display: block;
  }
  100% {
    margin-left: 0px;
    width: 270px;
    display: block;
  }
}
@media (min-width: 576px) {
  .side-menu {
    width: 270px;
    -webkit-animation: op 0.2s normal forwards ease-in-out; /* Safari */
    animation: op 0.2s normal forwards ease-in-out;
    position: fixed;
    min-height: calc(100vh);
  }
  .btn-toggle-right {
    -webkit-animation: opbtn 1s normal forwards ease-in-out; /* Safari */
    animation: opbtn 1s normal forwards ease-in-out;
    border: 0;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 20px;
    left: 270px;
    background-color: #0000;
    z-index: 1000;
  }
  .m-t-30 {
    margin-top: 20px;
  }
}
@media (min-width: 768px) {
  .side-menu {
    width: 270px;
    -webkit-animation: opb 0.2s normal forwards ease-in-out; /* Safari */
    animation: opb 0.2s normal forwards ease-in-out;
    position: relative;
  }
  .side-menu-hidden {
    width: 270px;
    display: block;
    position: relative;
    -webkit-animation: csob 0.4s normal forwards ease-in-out; /* Safari */
    animation: csob 0.4s normal forwards ease-in-out;
  }
  .btn-toggle-right {
    -webkit-animation: opbtn 1s normal forwards ease-in-out; /* Safari */
    animation: opbtn 1s normal forwards ease-in-out;
    border: 0;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 20px;
    left: 270px;
    background-color: #0000;
    z-index: 1000;
  }
  .m-t-30 {
    margin-top: 20px;
  }
}
@media (min-width: 992px) {
  .side-menu {
    width: 270px;
    -webkit-animation: opb 0.2s normal forwards ease-in-out; /* Safari */
    animation: opb 0.2s normal forwards ease-in-out;
    position: relative;
  }
  .side-menu-hidden {
    width: 270px;
    display: block;
    position: relative;
    -webkit-animation: csob 0.4s normal forwards ease-in-out; /* Safari */
    animation: csob 0.4s normal forwards ease-in-out;
  }
  .btn-toggle-right {
    -webkit-animation: opbtn 1s normal forwards ease-in-out; /* Safari */
    animation: opbtn 1s normal forwards ease-in-out;
    border: 0;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 20px;
    left: 270px;
    background-color: #0000;
    z-index: 1000;
  }
  .m-t-30 {
    margin-top: 20px;
  }
}
@media (min-width: 1200px) {
  .side-menu {
    width: 270px;
    -webkit-animation: opb 0.2s normal forwards ease-in-out; /* Safari */
    animation: opb 0.2s normal forwards ease-in-out;
    position: relative;
  }
  .side-menu-hidden {
    width: 270px;
    display: block;
    position: relative;
    -webkit-animation: csob 0.4s normal forwards ease-in-out; /* Safari */
    animation: csob 0.4s normal forwards ease-in-out;
  }
  .btn-toggle-right {
    -webkit-animation: opbtn 1s normal forwards ease-in-out; /* Safari */
    animation: opbtn 1s normal forwards ease-in-out;
    border: 0;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 20px;
    left: 270px;
    background-color: #0000;
    z-index: 1000;
  }
  .m-t-30 {
    margin-top: -30px;
  }
}

/* Safari */
@-webkit-keyframes op {
  0% {
    -webkit-margin-start: -270px;
  }
  100% {
    -webkit-margin-start: 0px;
  }
}

@keyframes op {
  0% {
    margin-left: -270px;
  }
  100% {
    margin-left: 0px;
  }
}

/* Safari */
@-webkit-keyframes opb {
  0% {
    -webkit-margin-start: 0px;
  }
  100% {
    -webkit-margin-start: 0px;
  }
}

@keyframes opb {
  0% {
    -webkit-margin-start: 0px;
  }
  100% {
    margin-left: 0px;
  }
}
/* Safari */
@-webkit-keyframes opbtn {
  0% {
    -webkit-opacity: 0;
  }
  100% {
    -webkit-opacity: 1;
  }
}

@keyframes opbtn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.header-bar {
  height: 90px;
  background-color: #ffffff;
  display: flex;
  width: auto;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05), 0 3px 5px 0 rgba(0, 0, 0, 0.05);
  align-items: center;
  justify-content: flex-end;
  padding-right: 40px;
}
.profile-text {
  font-size: 16px;
  color: #707070;
  font-weight: 600;
}
.role-text {
  font-size: 16px;
  color: #a2a2a2;
}
.institution-image {
  width: 120px;
  height: 120px;
  border-radius: 60px;
  background-color: #cccccc;
}
.profile-image {
  width: 56px;
  height: 56px;
}
.big-icon {
  font-size: 54px;
  line-height: 54px;
}
.small-icon {
  font-size: 16px;
  line-height: 16px;
  width: 16px;
}
.search-box-div {
  display: inline;
  width: 300px;
  height: 44px;
}
.search-box {
  width: 300px;
  height: 42px;
  padding: 0.375rem 0.75rem;
  padding-right: 32px;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid #ced4da;
  border-radius: 6px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.icon-absolute {
  font-family: FontAwesome-light;
  src: local("MyFont"), url(./font/fa-light-300.woff) format("woff");
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
  padding: 12px;
  background-color: #162d4f;
  margin-left: -40px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 42px;
}
.filter-box {
  display: inline;
  width: 180px;
  height: 42px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid #ced4da;
  border-radius: 6px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
table {
  border-collapse: separate !important;
  border-spacing: 0;
  min-width: 350px;
  width: 100%;
}
table tr th,
table tr td {
  padding: 5px;
}
table tr th:first-child,
table tr td:first-child {
  border-left: 1px solid #bbb;
}
table tr th {
  border-top: 1px solid #bbb;
  text-align: left;
}

.tables th {
  border-radius: 0px !important;
  border: 1px solid #dadce0 !important;
  /* border-bottom: 0 !important; */
}
.tables tr {
  border-radius: 0px !important;
  border: 1px solid #dadce0 !important;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}
/* bottom-left border-radius */
table tr:last-child td {
  border-bottom: 1px solid #bbb;
}
table tr th:last-child {
  border-right: 1px solid #bbb;
}
table tr:first-child th {
  border-top: 1px solid #bbb;
}
table tr td:last-child {
  border-right: 1px solid #bbb;
}
/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
.days-bar {
  height: 34px;
  display: flex;
  flex-direction: row;
}
.day-left {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  width: 35px;
  background-color: #ebebeb;
  border: 1px solid #dedede;
  border-right-width: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.day-center {
  width: 35px;
  background-color: #ebebeb;
  border: 1px solid #dedede;
  border-right-width: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.day-right {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  width: 35px;
  background-color: #ebebeb;
  border: 1px solid #dedede;
  display: flex;
  justify-content: center;
  align-items: center;
}
.day-left-active {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  width: 35px;
  background-color: #162d4f;
  border: 1px solid #dedede;
  border-right-width: 0px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.day-center-active {
  width: 35px;
  background-color: #162d4f;
  border: 1px solid #dedede;
  border-right-width: 0px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.day-right-active {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  width: 35px;
  background-color: #162d4f;
  border: 1px solid #dedede;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.day-big-left {
  width: 14.285%;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background-color: #ebebeb;
  border: 1px solid #dedede;
  border-right-width: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.day-big-center {
  width: 14.285%;
  background-color: #ebebeb;
  border: 1px solid #dedede;
  border-right-width: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.day-big-right {
  width: 14.285%;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background-color: #ebebeb;
  border: 1px solid #dedede;
  display: flex;
  justify-content: center;
  align-items: center;
}
.day-big-left-active {
  width: 14.285%;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background-color: #162d4f;
  border: 1px solid #dedede;
  border-right-width: 0px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.day-big-center-active {
  width: 14.285%;
  background-color: #162d4f;
  border: 1px solid #dedede;
  border-right-width: 0px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.day-big-right-active {
  width: 14.285%;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background-color: #162d4f;
  border: 1px solid #dedede;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.toggle-bar {
  height: 40px;
  display: flex;
  flex-direction: row;
}
.toggle-left {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background-color: #ebebeb;
  border: 1px solid #dedede;
  border-right-width: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}
.toggle-right {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background-color: #ebebeb;
  border: 1px solid #dedede;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}
.toggle-left-active {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background-color: #162d4f;
  border: 1px solid #dedede;
  border-right-width: 0px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}
.toggle-right-active {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background-color: #162d4f;
  border: 1px solid #dedede;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}
.test {
  background-color: #50a759;
}
.test1 {
  background-color: #707070;
}
.test2 {
  background-color: #ff0000;
  height: auto;
}
.dash {
  border-top: 2px #dedede dashed;
}
#react-paginate ul {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;
}

#react-paginate li {
  display: inline-block;
}

.pagination li {
  border-left: 1px #cecece solid;
  border-top: 1px #cecece solid;
  border-bottom: 1px #cecece solid;
  padding: 0px 10px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination li:first-child {
  border-left: 1px #cecece solid;
  border-top: 1px #cecece solid;
  border-bottom: 1px #cecece solid;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagination li:last-child {
  border: 1px #cecece solid;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagination .disabled {
  background-color: #f2f2f2;
}
.pagination .disabled a {
  color: #cecece;
}
.pagination .active {
  background-color: #162d4f;
}
.pagination .active a {
  color: #ffffff;
}
.overflow-y {
  overflow-y: auto;
}
.overflow-x {
  overflow-x: scroll;
}
.overflow-x-none {
  overflow-x: unset !important;
}
.table-w-9 {
  min-width: 900px;
}
.table-w-1 {
  min-width: 1100px;
}
.table-w-1-3 {
  min-width: 1300px;
}
.table-w-2 {
  min-width: 720px;
}
.table-w-2-1 {
  min-width: 650px;
}
.table-w-2-2 {
  min-width: 500px;
}
.table-w-2-3 {
  min-width: 400px;
}
.w-100-body {
  right: 0px;
  overflow-x: auto;
  width: 100% !important;
  right: 0;
}
ul {
  list-style-type: none;
  padding-left: 0;
}
li {
  list-style-type: none;
}
.top-dash {
  border-top: 2px dashed #dadce0;
}
.right-solid {
  border-right: 1px solid #cccccc;
}
.img-contain {
  object-fit: contain;
}
.m-t-10 {
  margin-top: -10px;
}
.card-icon {
  width: 24px;
  height: 24px;
  font-size: 24px;
  line-height: 24px;
  margin-top: -62px;
  margin-right: 10px;
}
.h-48 {
  height: 113px !important;
}
.py-2 {
  padding-top: 0;
  padding-bottom: 0;
}
.pb-2,
.py-2 {
  padding-bottom: 0 !important;
}
.pt-2,
.py-2 {
  padding-top: 0 !important;
}

h3,
.h3 {
  font-size: 1.75rem !important;
}
h4,
.h4 {
  font-size: 1.5rem !important;
}
.font-medium {
  font-size: 14px !important;
  font-weight: 300 !important;
}
.m-t-input {
  margin-top: -37px;
}
.m-t-input-form {
  margin-top: -38px;
  width: 50px;
}
a {
  text-decoration: none;
}
.swal2-confirm {
  background-color: #162d4f !important;
}
.swal2-cancel {
  background-color: #cecece !important;
}
.swal2-confirm {
  color: #fff !important;
  background-color: #162d4f !important;
  border-color: #162d4f !important;
}

.swal2-confirm:hover {
  color: #fff !important;
  background-color: #0b1626 !important;
  border-color: #0b1626 !important;
}

.swal2-confirm:focus,
.swal2-confirm.focus {
  box-shadow: 0 0 0 0.2rem #0b1626 !important;
}
#snackbar {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1100;
  left: 50%;
  bottom: 30px;
  font-size: 17px;
}

#snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}
.overflow-x-auto {
  overflow-x: auto;
}
.menu-context-box {
  background-color: #fff;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
  max-width: 300px;
  min-width: 150px;
  position: absolute;
  z-index: 1001;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.menu-context-box-xl {
  background-color: #fff;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
  max-width: 400px;
  min-width: 250px;
  position: absolute;
  z-index: 1001;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.active {
  display: block;
}
.hidden {
  display: none;
}
.menu-context-button {
  padding-left: 10px;
  padding-top: 5px;
  width: 100%;
  text-align: left;
  border-radius: 0;
  color: #707070;
}
.menu-context-button:hover {
  background-color: #ebebeb;
  padding-left: 10px;
  padding-top: 5px;
  width: 100%;
  text-align: left;
  border-radius: 0;
}
.menu-context-button:active {
  padding-left: 10px;
  padding-top: 5px;
  width: 100%;
  text-align: left;
  border-radius: 0;
}
.search-select-max-height {
  max-height: 150px;
}
.search-button-select:hover {
  background-color: #ebebeb;
}
.search-button-select:focus {
  border: 0;
}
.selected-box {
  padding-left: 8px;
  padding-right: 8px;
  height: 25px;
  border-radius: 15px;
}
.btn-link {
  text-decoration: none !important;
}
.w-100-w {
  max-width: 200px;
}
.form-control-style {
  display: flex;
  width: 100%;
  min-height: 38px;
  padding-left: 18px;
  color: var(--dark);
  background-color: var(--white);
  background-clip: padding-box;
  border: 1px solid var(--light-dark);
  border-radius: 0.25rem;
  align-items: center;
  font-size: 1rem;
}
.mt--2 {
  margin-top: -22px;
}
#zmmtg-root {
  display: none;
}
.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #162d4f;
  width: 30px;
  height: 30px;
  -webkit-animation: spinx 2s linear infinite; /* Safari */
  animation: spinx 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spinx {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinx {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.point {
  cursor: pointer;
}
.react-datepicker-wrapper {
  width: 100%;
}
.spinner {
  position: fixed;
  z-index: 1200;
  width: 100%;
  min-height: 100%;
  height: 100%;
  background-color: #00000030;
  justify-content: center;
  align-items: center;
  display: flex;
}
.text-big {
  font-size: 200px;
  color: #162d4f;
}
.text-big2 {
  font-size: 100px;
  color: #162d4f;
}
.text-medium {
  font-size: 26px;
}
.oop {
  max-width: 600px;
}
.toast {
  position: fixed;
  z-index: 1500;
  width: 320px;
  padding: 10px;
  bottom: 0;
  right: 0;
}
.box-toast {
  width: 300px;
  background-color: #fff;
  margin-top: 10px;
  padding: 10px;
  bottom: 0;
  right: 0;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.box-toast-hidden {
  width: 0;
  height: 0;
  margin-top: 0;
  bottom: 0;
  right: 0;
}
.d-flex-i {
  display: flex;
  flex-direction: row;
}
.max-width-small {
  width: 75px;
}
.max-width-medium {
  width: 150px;
}
.btn {
  border-radius: 6px !important;
  height: 42px;
  vertical-align: top;
}
.btn-outline-primary {
  border-width: 2px !important;
}
.btn-outline-secondary {
  border-width: 2px !important;
}
.border-r-0 {
  border-radius: 0 !important;
}
td {
  vertical-align: middle !important;
}
.error {
  border-color: tomato;
}
.w-50 {
  width: "50%";
}
.w-30 {
  width: "30%";
}
.w-20 {
  width: "20%";
}
.w-10 {
  width: "10%";
}
.month {
  width: 230px;
}
.week {
  width: "14.2857%";
}

/* .rbc-btn-group {
  display: none  !important;
} */
.rbc-toolbar .rbc-toolbar-label {
  /* opacity: 0; */
  text-align: left !important;
}
.rbc-toolbar .rbc-btn-group:first-child {
  display: none;
}
.rbc-time-view
  .rbc-time-header
  .rbc-time-header-content
  .rbc-allday-cell
  .rbc-row-content
  .rbc-addons-dnd-row-body
  .rbc-row
  .rbc-row-segment
  .rbc-event {
  margin-top: 0px !important;
}
@media (min-width: 576px) {
  .modal-xl {
    max-width: 1800px !important;
    margin: 1.75rem auto;
  }
  .modal-lg {
    max-width: 1024px !important;
    margin: 1.75rem auto;
  }
}
.modal-xl {
  min-width: 300px !important;
}
.modal-lg {
  min-width: 300px !important;
}
.rbc-month-view {
  border-radius: 6px;
}
.rbc-toolbar-label {
  display: none;
}
.rbc-header {
  background-color: #343a40;
  color: #ffffff;
  padding-top: 6px;
  height: 36px;
}
.rbc-header:first-child {
  border-top-left-radius: 6px;
}
.rbc-header:last-child {
  border-top-right-radius: 6px;
}
.blur {
  -webkit-animation: b 0.1s normal forwards ease-in-out; /* Safari */
  animation: b 0.1s normal forwards ease-in-out;
}

/* Safari */
@-webkit-keyframes b {
  0% {
    -webkit-backdrop-filter: blur(0px);
  }
  100% {
    -webkit-backdrop-filter: blur(20px);
  }
}

@keyframes b {
  0% {
    backdrop-filter: blur(0px);
  }
  100% {
    backdrop-filter: blur(20px);
  }
}
.modal {
  backdrop-filter: blur(5px);
}
.rbc-off-range {
  /* opacity: 0; */
  z-index: -10;
}
